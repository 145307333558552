<template>
  <div class="mes">
    <tabBar :cardIndex="10" @meactive="meactive" ref="child" />
    <div class="mecenters">
      <div class="me_left" ref="textareaRef">
        <div
          class="li"
          v-for="(item, index) in pageList"
          :key="index"
          ref="meleftli"
        >
          <div class="titles">{{ item.name }}</div>
          <div
            class="toppage"
            v-for="(ime, idx) in item.children"
            :key="idx"
            @click="topages(ime,index,idx)" :class="ime.isfor?'actives':''"
          >
            {{ ime.name }}
						<el-badge :value="ordercode" :max="99" class="item" v-if="ime.code==1&&ordercode>0"></el-badge>
						<el-badge :value="msgcode" :max="99" class="item" v-if="ime.code==2&&msgcode>0"></el-badge>
          </div>
        </div>
      </div>
      <div class="me_right">
        <router-view v-if="active != 0" />
        <myiffo @activeClick="activeClick" :infoobg="infoobg" v-if="active==0">
        </myiffo>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import tabBar from "@/components/tabBar.vue";
import footers from "@/components/footer.vue";
import myiffo from "./me.vue";
import { getUserInfo } from "../../request/homeApi";
export default {
  components: {
    tabBar,
    footers,
    myiffo,
  },
  data() {
    return {
      route: this.$route,
      active: 0,
      infoobg: {},
			ordercode:0,
			msgcode:0,
      pageList: [
        {
          name: "订单中心",
          id: "0",
          children: [
            {
              name: "我的订单",
              id: "0",
              url: "/mepages/myoder?active=1",
							isfor:false,
							code:1
            },
            {
              name: "收货地址",
              id: "0",
              url: "/mepages/shippingaddress?active=4",
							isfor:false
            },
            {
              name: "售后订单",
              id: "0",
              url: "/mepages/aftersaleOder?active=5",
							isfor:false
            },
            {
              name: "购物车",
              id: "0",
              url: "/mepages/mycart?active=6",
							isfor:false
            },
          ],
        },
        {
          name: "会员中心",
          id: "2",
          children: [
            {
              name: "用户信息",
              id: "0",
              url: "/mepages/users?active=7",
							isfor:false
            },
            {
              name: "修改密码",
              id: "0",
              url: "/mepages/password?active=8",
							isfor:false
            },
            {
              name: "我的评论",
              id: "0",
              url: "/mepages/comment?active=9",
							isfor:false
            },
            {
              name: "我的消息",
              id: "0",
              url: "/mepages/news?active=3",
							isfor:false,
							code:2
            },
            {
              name: "我的帖子",
              id: "0",
              url: "/mepages/posts?active=2",
							isfor:false
            },
          ],
        },
        {
          name: "优惠券",
          id: "3",
          children: [
            {
              name: "奥普斯商城",
              id: "0",
              url: "/OpusMall",
							isfor:false
            },
            {
              name: "我的优惠券",
              id: "0",
              url: "/mepages/coupon?active=10",
							isfor:false
            },
            {
              name: "领券中心",
              id: "0",
              url: "/mepages/couponcoll?active=11",
							isfor:false
            },
          ],
        },
      ],
    };
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
				console.log(val)
				this.active = val.query.active;
				let page=val.query.page;
				let chind=val.query.chind;
				this.pageList.forEach((item,index)=>{
					item.children.forEach((ites,ind)=>{
						ites.isfor=false
					})
				})
				if(this.active==1){
					this.$set(this.pageList[0].children[0],'isfor',true)
				}else if(this.active==2){
					this.$set(this.pageList[1].children[4],'isfor',true)
				}else if(this.active==3){
					this.$set(this.pageList[1].children[3],'isfor',true)
				}else if(this.active==11){
					this.$set(this.pageList[2].children[2],'isfor',true)
				}else{
					if(page){
						this.$set(this.pageList[page].children[chind],'isfor',true)
					}
				}
				this.infoapi()
				this.$refs.child.getuser();
        // if(val.fullPath == '/mepages/index?active=0'){
        //    this.active = 0;
        // }
      },
    },
  },
  mounted() {
		if (this.$route.query.active) {
		  this.active = this.$route.query.active;
		}
		if(this.active==1){
			this.$set(this.pageList[0].children[0],'isfor',true)
		}else if(this.active==2){
			this.$set(this.pageList[1].children[4],'isfor',true)
		}else if(this.active==3){
			this.$set(this.pageList[1].children[3],'isfor',true)
		}else if(this.active==11){
			this.$set(this.pageList[2].children[2],'isfor',true)
		}else{
			let page=this.$route.query.page;
			let chind=this.$route.query.chind;
			if(page){
				this.$set(this.pageList[page].children[chind],'isfor',true)
			}
		}
		//console.log(this.active)
    // if (this.$route.query.active == 1) {
    //   this.active = 1;
    // } else {
    //   setTimeout(() => {
    //     this.active = 0;
    //   }, 700);
    // }

    this.$refs.textareaRef.style.height =
      this.$refs.meleftli[0].clientHeight +
      this.$refs.meleftli[1].clientHeight +
      this.$refs.meleftli[2].clientHeight +
      "px";
    this.infoapi();
  },
  methods: {
    meactive(e) {
      this.active = e;
    },
    activeClick(e) {
      this.active = e;
    },
    infoapi() {
      getUserInfo()
        .then((res) => {
          if (res.code == 200) {
            this.infoobg = res.result;
						this.ordercode=res.result.orderMsgCount
						this.msgcode=res.result.msgCount
          }
        })
        .catch((res) => {
        });
    },
    topages(item,index,inds) {
      //this.active = 1;
			// this.pageList.forEach((item,index)=>{
			// 	item.children.forEach((ites,ind)=>{
			// 		ites.isfor=false
			// 	})
			// })
			// this.$set(item,'isfor',!item.isfor)
			this.$refs.child.sing();
			if(index==2&&inds==0){
				this.$router.push({
					path: item.url
				});
			}else{
				if(index==0&&inds==0){
					this.$router.push({
						path: item.url,
						query:{page:index,chind:inds,code:this.ordercode}
					});
				}else if(index==1&&inds==3){
					this.$router.push({
						path: item.url,
						query:{page:index,chind:inds,code:this.msgcode}
					});
				}else{
					this.$router.push({
						path: item.url,
						query:{page:index,chind:inds}
					});
				}
			}
    },
  },
};
</script>

<style lang="scss" scoped>
.mes {
  background: #eff4f1;
}
.mecenters {
  padding-top: 120px;
  display: flex;
  width: 1250px;
  margin: 0 auto;
  .me_left {
    width: 180px;

    background: #feffff;
    box-shadow: 0px 8px 7px 1px rgba(55, 55, 55, 0.01);
    display: inline-block !important;
    .li {
      padding: 24px 33px;
      border-bottom: 1px solid #f3f3f3;
      .titles {
        font-size: 18px;
        color: #535353;
      }
      .toppage {
        margin-top: 16px;
        font-size: 16px;
        color: #999999;
        cursor: pointer;
				position: relative;
				::v-deep .el-badge{
					position: absolute;
					top: 0;
					right: 18px;
				}
      }
			.actives{
				color: #00a3e0;
			}
    }
  }
  .me_right {
    width: 1054px;
    margin-left: 16px;
  }
}
</style>