<template>
  <div style="margin-bottom: 118px">
    <div class="metops">
      <div class="title">基本信息</div>
      <div class="otuxiangimg" v-if="infoobg.member">
        <div class="tophedaers">
          <div class="touxing">
            <div class="imgdiv">
              <img
                class="img"
                :src="infoobg.member.face"
                alt=""
                v-if="infoobg.member.face"
              />
              <img v-else class="img" src="@/assets/img/user.png" />
              <div class="dengji" style="text-align: center;">{{ infoobg.member.levelName }}</div>
            </div>
            <div class="names">{{ infoobg.member.nickName }}</div>
          </div>
          <div v-if="!infoobg.member.isCoupon" style="text-align: center">
            <img id="imgids" :src="infoobg.member.qrCode" alt="" />
            <div v-if="infoobg.member.isCoupon">美白券核销码</div>
          </div>
        </div>
        <div class="lsitmesg">
          <div class="lis">
            <div class="tops">{{ infoobg.member.mobile }}</div>
            <div class="bottoms">
              <img
                class="icons shouji"
                src="../../assets/img/shouji.png"
                alt=""
              />
              <span class="textcen">绑定手机</span>
              <span class="textont" @click="tophones">更换</span>
            </div>
          </div>
          <div class="lis">
            <div class="tops imgads">
              <img
                v-if="infoobg.member.levelId == 0"
                class="img"
                src="../../assets/img/tong.png"
                alt=""
              />
              <img
                v-if="infoobg.member.levelId == 1"
                class="img"
                src="../../assets/img/yin.png"
                alt=""
              />
              <img
                v-if="infoobg.member.levelId == 2"
                class="img"
                src="../../assets/img/jin.png"
                alt=""
              />
            </div>
            <div class="bottoms">
              <img
                class="icons dengji"
                src="../../assets/img/dengji.png"
                alt=""
              />
              <span class="textcen">会员等级</span>
              <span class="textont" @click="quanyimoal">权益</span>
            </div>
          </div>
          <div class="lis">
            <div class="tops">{{ infoobg.memberCouponsNum }}</div>
            <div class="bottoms">
              <img class="icons" src="../../assets/img/quan.png" alt="" />
              <span class="textcen">优惠券</span>
              <span class="textont" @click="topageLingquan">领券</span>
            </div>
          </div>
          <div class="lis">
            <div class="tops">{{ infoobg.member.totalPoint }}</div>
            <div class="bottoms">
              <img class="icons" src="../../assets/img/jifen.png" alt="" />
              <span class="textcen">累计积分</span>
              <span class="textont" @click="jifenguize">积分规则</span>
            </div>
          </div>
          <div class="lis">
            <div class="tops">{{ infoobg.member.point }}</div>
            <div class="bottoms">
              <img class="icons" src="../../assets/img/jifen.png" alt="" />
              <span class="textcen">当前积分</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="muoders">
      <div class="oderlsit">
        <div class="title">我的订单</div>
        <div class="lsitcard">
          <div class="cardli" @click="tooders('WAIT_PAY')">
            <img
              class="oderimg imgone1"
              src="../../assets/img/daifukuan.png"
              alt=""
            />
            <img
              class="oderimg imgtwo1"
              src="../../assets/img/daifukuan2.png"
              alt=""
            />
            <div class="names">待付款</div>
          </div>
          <div class="cardli" @click="tooders('WAIT_ROG')">
            <img
              class="oderimg imgone2"
              src="../../assets/img/daishouhuo.png"
              alt=""
            />
            <img
              class="oderimg imgtwo2"
              src="../../assets/img/daishouhuo2.png"
              alt=""
            />
            <div class="names">待收货</div>
          </div>
          <div class="cardli" @click="tooders('COMPLETE')">
            <img
              class="oderimg imgone3"
              src="../../assets/img/yiwanchneg.png"
              alt=""
            />
            <img
              class="oderimg imgtwo3"
              src="../../assets/img/yiwanchneg2.png"
              alt=""
            />
            <div class="names">已完成</div>
          </div>
          <div class="cardli" @click="tooshouders()">
            <img
              class="oderimg imgone4"
              src="../../assets/img/shouhou.png"
              alt=""
            />
            <img
              class="oderimg imgtwo4"
              src="../../assets/img/shouhou2.png"
              alt=""
            />
            <div class="names">退款/售后</div>
          </div>
          <div class="cardli" @click="tooders('ALL')">
            <img
              class="oderimg imgone5"
              src="../../assets/img/quanbu.png"
              alt=""
            />
            <img
              class="oderimg imgtwo5"
              src="../../assets/img/quanbu2.png"
              alt=""
            />
            <div class="names">全部订单</div>
          </div>
        </div>
      </div>
      <div class="qiandano" @click="topmaodal">
        <img src="../../assets/img/qiandao.png" alt="" />
      </div>
    </div>
    <div class="tuigoods" v-if="infoobg.goodsList">
      <div class="title">推荐商品</div>
      <div class="goodsop">
        <div
          class="goodsli"
          v-for="(item, index) in infoobg.goodsList.content"
          :key="index"
          @click="clickLites(item)"
        >
          <div
            :style="
              'background:url(' +
              item.content.thumbnail +
              ') no-repeat;overflow: hidden;background-position: center center; background-repeat: no-repeat;-webkit-background-size: cover;-moz-background-size: cover; background-size: cover;'
            "
            class="goodsimg"
          ></div>
          <div class="goodstitle">{{ item.content.goodsName }}</div>
          <div class="goodspric">
            <span class="rmbico">￥</span>{{ item.content.price }}
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="会员权益"
      :visible.sync="dialogVisible"
      :show-close="false"
      width="30%"
    >
      <div class="centers" style="margin:0">
        <div class="titiles">会员为终身会员</div>
        <div class="li">
          <span class="dian">·</span>铜牌会员：享受1：100积分
        </div>
        <div class="li">
          <span class="dian">·</span> 银牌会员：享受1：200积分
        </div>
        <div class="li">
          <span class="dian">·</span>
          金牌会员：享受1：500积分，所有商品包邮，免费牙齿美白治疗（每年各一次）。
          <br/>
          <br/>
          当积分满500,000分时，系统自动为该会员发放一张牙齿美白券，至当年年底（12月31日）失效，第二年1月1日重新发放一张，至年底失效。每年如此。
        </div>
        <div class="ads">【注意】产生退货退款的商品，积分需要退回。</div>
        <div class="btnsmodal" style="margin-top:30px" @click="renovesmodal">我知道了</div>
      </div>
    </el-dialog>
    <el-dialog
      title="积分规则"
      :visible.sync="dialogjifenguize"
      :show-close="false"
      width="30%"
    >
      <div class="centers">
        <div class="titiles">会员为终身会员</div>
        <div class="li">
          <span class="dian">·</span
          >连续签到得积分：以10分基数，第一天签到得10分，第二天20分...以此类推，每日累加，上限50分（连续
          第5天得50分，第六、七天50分），断开一天，从10分重新开始。
        </div>
        <div class="li">
          <span class="dian">·</span>
          评论论坛得积分：发布一条评论得1分，10分为上限，即每人每天最高得10分。
        </div>
        <div class="li">
          <span class="dian">·</span>
          发帖子送积分：发布一条帖子并且通过审核得10分，100分为上限，即每人每天最高得100分。
        </div>
        <div class="ads" style="margin-bottom: 5px">
          【注意】论坛内容被删除后，此积分相对应扣减，最低减至0。
        </div>
        <div style="height:5px"></div>
        <div class="titiles">积分使用和有效期：</div>
        <div class="li">
          <span class="dian"></span
          >积分可用于【积分商城】页面兑换积分产品。获得积分后终身有效不过期。
        </div>
        <div style="height:5px"></div>
        <div class="titiles">积分等级</div>
        <div class="li">
          <span class="dian"></span
          >消费1元，积分100。注册成功默认为铜牌会员；满10,0000分，成为银牌会员；满500,000分，成为金牌
          会员。
        </div>
      </div>

      <div class="btnsmodal" @click="renovesmodal">我知道了</div>
    </el-dialog>
    <div class="tanchucenbgt" v-if="ismodals">
      <div class="qiandaocen">
        <img class="img" src="../../assets/img/qiandoa.png" alt="" />
        <div class="topcenter">
          <div class="guanbi">
            <i class="el-icon-circle-close" @click="removes"></i>
          </div>
          <div class="bottomsbtn">
            <div class="centertext">
              今日签到获得{{ numbers >= 5 ? 50 : numbers * 10 }}积分
            </div>
            <p class="mesges">积分可用于兑换商品</p>
            <div class="looke" @click="chakjanjifen">查看积分规则</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { postUsersign } from "../../request/homeApi";
export default {
  data() {
    return {
      numbers: 0,
      dialogVisible: false,
      ismodals: false,
      dialogjifenguize: false,
    };
  },
  props: {
    infoobg: {
      type: Object,
      default: {},
    },
  },
  methods: {
    tooshouders() {
      this.$emit("activeClick", 1);
      this.$router.push({ path: "/mepages/aftersaleOder" });
    },
    tooders(type) {
      this.$emit("activeClick", 1);
      this.$router.push({ path: "/mepages/myoder?type=" + type });
    },
    clickLites(item) {
      this.$router.push({ path: "/shopdet?&id=" + item.id });
    },
    chakjanjifen() {
      this.ismodals = false;
      this.dialogjifenguize = true;
    },
    // 打开关闭模态框
    topmaodal() {
      postUsersign()
        .then((res) => {
          if (res.code == 200) {
            this.numbers = res.result;
            this.ismodals = true;
          }
        })
        .catch((res) => {
          console.log("网络开小差了，没有获取到图片验证码哦");
        });
    },
    removes() {
      this.ismodals = false;
    },
    topageLingquan() {
      this.$emit("activeClick", 1);
      this.$router.push({ path: "/mepages/couponcoll" });
    },
    jifenguize() {
      this.dialogjifenguize = true;
    },
    tophones() {
      this.$emit("activeClick", 1);
      this.$router.push({ path: "/mepages/users" });
    },
    quanyimoal() {
      this.dialogVisible = true;
    },
    renovesmodal() {
      this.dialogVisible = false;
      this.dialogjifenguize = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.metops {
  width: 100%;
  background: #ffffff;
  .title {
    height: 63px;
    padding-left: 40px;
    line-height: 63px;
    font-size: 18px;
    color: #535353;
    border-bottom: 1px solid #f3f3f3;
  }
  .otuxiangimg {
    padding: 20px 40px;
    .tophedaers {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .touxing {
        display: flex;
        align-items: center;
        .imgdiv {
          width: 68px;
          height: 68px;
          border-radius: 50%;
          position: relative;
          .img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
          .dengji {
            display: inline-block !important;
            position: absolute;
            bottom: -17px;
            left: 0px;
            right: 0;
            margin: auto;
            width: 44px;
            height: 20px;
            //padding: 4px 9px;
            text-align: center;
            border-radius: 40px;
            font-size: 12px;
            white-space: nowrap;
            color: #535353;
            background: #ffffff;
            border: 1px solid #f0f0f0;
          }
        }
        .names {
          margin-left: 14px;
        }
      }
    }

    .lsitmesg {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 46px;
      margin-bottom: 10px;
      .lis {
        text-align: center;
        .tops {
          font-size: 24px;
          color: #333333;
        }
        .imgads {
          width: 20px;
          height: 26px;
          margin: 0 auto;
          .img {
            width: 100%;
            height: 100%;
          }
        }
        .bottoms {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          margin-top: 7px;
          color: #818181;
          .icons {
            width: 21px;
            height: 18px;
          }
          .shouji {
            width: 10px;
            height: 18px;
          }
          .dengji {
            width: 21px;
            height: 18px;
          }
          .textcen {
            margin-left: 7px;
            margin-right: 17px;
          }
          .textont {
            color: #4cb6e6;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.muoders {
  display: flex;
  justify-content: flex-start;
  margin-top: 16px;
  //   align-items: center;
  .oderlsit {
    width: 792px;
    height: 240px;
    background: #feffff;
    box-shadow: 0px 8px 7px 1px rgba(55, 55, 55, 0.01);
    .title {
      height: 63px;
      padding-left: 40px;
      line-height: 63px;
      font-size: 18px;
      color: #535353;
      border-bottom: 1px solid #f3f3f3;
    }
    .lsitcard {
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 45px 20px 56px;
      .cardli {
        text-align: center;
        cursor: pointer;
        .oderimg {
          width: 42px;
          height: 42px;
          margin-bottom: 17px;
        }
        .imgtwo1 {
          display: none;
        }
        .imgtwo2 {
          display: none;
        }
        .imgtwo3 {
          display: none;
        }
        .imgtwo4 {
          display: none;
        }
        .imgtwo5 {
          display: none;
        }
        .names {
          font-size: 16px;
          color: #818181;
        }
      }
      .cardli:hover .names {
        color: #4cb6e6;
      }
      .cardli:hover .imgone1 {
        display: none;
      }
      .cardli:hover .imgtwo1 {
        display: block;
        margin: 0 auto;
        margin-bottom: 17px;
      }
      .cardli:hover .imgone2 {
        display: none;
      }
      .cardli:hover .imgtwo2 {
        display: block;
        margin: 0 auto;
        margin-bottom: 17px;
      }
      .cardli:hover .imgone3 {
        display: none;
      }
      .cardli:hover .imgtwo3 {
        display: block;
        margin: 0 auto;
        margin-bottom: 17px;
      }

      .cardli:hover .imgone4 {
        display: none;
      }
      .cardli:hover .imgtwo4 {
        display: block;
        margin: 0 auto;
        margin-bottom: 17px;
      }
      .cardli:hover .imgone5 {
        display: none;
      }
      .cardli:hover .imgtwo5 {
        display: block;
        margin: 0 auto;
        margin-bottom: 17px;
      }
    }
  }
  .qiandano {
    width: 250px;
    height: 134px;
    margin-left: 10px;
    //background: linear-gradient(161deg, #4ad1f6, #0f9ce9);
    //box-shadow: 0px 8px 7px 1px rgba(55, 55, 55, 0.01);
    cursor: pointer;
  }
}
.tuigoods {
  width: 792px;
  background: #feffff;
  box-shadow: 0px 8px 7px 1px rgba(55, 55, 55, 0.01);
  .title {
    height: 63px;
    padding-left: 40px;
    line-height: 63px;
    font-size: 18px;
    color: #535353;
    border-bottom: 1px solid #f3f3f3;
  }
  .goodsop {
    display: flex;
    // flex-wrap: wrap;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px;
    .goodsli {
      width: 242px;
      //   height: 206px;
      margin-bottom: 20px;
      padding-bottom: 15px;
      background: #ffffff;
      box-shadow: 0px 4px 4px 0px rgba(55, 55, 55, 0.04);
      cursor: pointer;
      .goodsimg {
        width: 242px;
        height: 138px;
      }
      .goodstitle {
        margin-top: 6px;
        padding: 0 10px;
        font-size: 15px;
        color: #535353;
      }
      .goodspric {
        margin-top: 15px;
        padding: 0 10px;
        font-size: 13px;
        font-weight: normal;
        color: #00a3e0;
        .rmbico {
          font-size: 12px;
        }
      }
    }
  }
  .goodsop::after {
    content: "";
    width: 242px;
  }
}
.centers {
  font-size: 14px;
  color: #535353;
  margin-bottom: 45px;
  .titiles {
    color: #535353;
    font-size: 14px;
  }
  .li {
    // padding-left: 10px;
    .dian {
      font-size: 18px;
    }
  }
}
.btnsmodal {
  width: 100px;
  height: 38px;
  margin: 0 auto;
  color: #ffffff;
  text-align: center;
  line-height: 38px;
  background: #00a3e0;
  border-radius: 2px;
  cursor: pointer;
}
.tanchucenbgt {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9999;
  height: 100%;
  .qiandaocen {
    width: 305px;
    height: 465px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    .img {
      width: 100%;
      height: 100%;
    }
    .topcenter {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      .guanbi {
        width: 100%;
        height: 24px;
        font-size: 24px;
        text-align: right;
        color: #ffffff;
        cursor: pointer;
      }
      .bottomsbtn {
        position: absolute;
        height: 34%;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        padding: 0 40px;
        .centertext {
          text-align: center;
          font-size: 22px;
          color: #555555;
        }
        .mesges {
          font-size: 14px;
          color: #999999;
          text-align: center;
        }
        .looke {
          background: #f9b535;
          font-size: 16px;
          color: #ffffff;
          border-radius: 4px;
          text-align: center;
          padding: 5px 0;
          width: 95%;
          margin: 0 auto;
          margin-top: 42px;
          cursor: pointer;
        }
      }
    }
  }
}
#imgids {
  width: 100px;
}
</style>